export const ElementIds = {
  originId: 'originId',
  destinationId: 'destinationId',
  senderId: 'senderId',
  fetchPriceBtnId: 'fetch-price-btn',
  classOfServiceElementId: 'id_class_of_service',
  classOfServiceInputValueErrMsgId: 'id_classofserviceError',
  pricingSpinnerOverlayId: 'pricing-spinner-overlay',
  errorMessageTextId: 'error-message-text',
  errorMessageContainerId: 'error-message-container',
  newPriceInputId: 'new-parcel-price',
  generateCollectionDeliveryBtnId: 'save-and-generate-delivery',
  saveAndEditPriceBtnId: 'save-and-edit',
  collectionId: 'collectionId',
  alertContainerWrapperId: 'deliveryAlertContainer',
  alertPrimaryTextContainerId: 'alertPrimaryText',
  alertSecondaryTextContainerId: 'alertSecondaryText',
  priceDisplayContainerId: 'priceDisplayContainer',
  orgCanEditPriceId: 'org-edit-price',
  defaultSKUTaxRateId: 'default-sku-tax-rate',
  parcel_size_id: 'id_parcel_size',
  lengthElementId: 'id_length',
  lengthInputValueErrMsgId: 'id_lengthError',
  widthElementId: 'id_width',
  widthInputValueErrMsgId: 'id_widthError',
  heightElementId: 'id_height',
  heightInputValueErrMsgId: 'id_heightError',
  quantityElementId: 'id_quantity',
  quantityInputValueErrMsgId: 'id_quantityError',
  taxTextId: 'taxText',
  totalTextId: 'totalText',
  subtotalTextId: 'subtotalText',
  newPriceInputValueErrMsgId: 'new-parcel-priceError',
  priceDisplayWrapperId: 'priceDisplayWrapper',
  weightInputId: 'id_weight',
  weightInputValueErrMsgId: 'id_weightError',
  ratingTableTextId: 'ratingTableTextId',
  rateCardTextId: 'rateCardTextId',
  rateCardAndTableTextParentId: 'rateCardAndTableTextParentId',
  skuTaxContainerId: 'skuTaxContainerId',
  orgVATTaxRateId: 'org_vat_tax_rate',
  selects: ['id_parcel_size', 'id_class_of_service'],
};

export function setupVariables(ElementIdsObj) {
  const ElementIds = ElementIdsObj;
  const constants = {
    MINIMUM_VALUE: 0,
    ZERO_RATED_VAT: parseFloat(0),
    FETCH_PRICE_URL: `/pricing/sku_pricing/`,
    classOfServiceElement: document.querySelector(
      `#${ElementIds.classOfServiceElementId}`,
    ),
    classOfServiceInputValueErrMsg: document.querySelector(
      `#${ElementIds.classOfServiceInputValueErrMsgId}`,
    ),
    // prettier-ignore
    originIdValue: document.querySelector(`#${ElementIds.originId}`).innerText.trim(),
    heightElement: document.querySelector(`#${ElementIds.heightElementId}`),
    heightInputValueErrMsg: document.querySelector(
      `#${ElementIds.heightInputValueErrMsgId}`,
    ),
    parcelSizeInput: document.querySelector(`#${ElementIds.parcel_size_id}`),
    widthInput: document.querySelector(`#${ElementIds.widthElementId}`),
    widthInputValueErrMsg: document.querySelector(
      `#${ElementIds.widthInputValueErrMsgId}`,
    ),
    lengthInput: document.querySelector(`#${ElementIds.lengthElementId}`),
    lengthInputValueErrMsg: document.querySelector(
      `#${ElementIds.lengthInputValueErrMsgId}`,
    ),
    weightInput: document.querySelector(`#${ElementIds.weightInputId}`),
    weightInputValueErrMsg: document.querySelector(
      `#${ElementIds.weightInputValueErrMsgId}`,
    ),
    destinationIdValue: document
      .querySelector(`#${ElementIds.destinationId}`)
      .innerText.trim(),
    senderIdValue: document
      .querySelector(`#${ElementIds.senderId}`)
      .innerText.trim(),
    fetchPriceBtn: document.querySelector(`#${ElementIds.fetchPriceBtnId}`),
    pricingSpinnerOverlay: document.querySelector(
      `#${ElementIds.pricingSpinnerOverlayId}`,
    ),
    csrf_token: document.querySelector('input[name=csrfmiddlewaretoken]'),
    errorMessageText: document.querySelector(
      `#${ElementIds.errorMessageTextId}`,
    ),
    errorMessageContainer: document.querySelector(
      `#${ElementIds.errorMessageContainerId}`,
    ),
    newPriceInput: document.querySelector(`#${ElementIds.newPriceInputId}`),
    generateCollectionDeliveryBtn: document.querySelector(
      `#${ElementIds.generateCollectionDeliveryBtnId}`,
    ),
    saveAndEditPriceBtn: document.querySelector(
      `#${ElementIds.saveAndEditPriceBtnId}`,
    ),
    newPriceInputValueErrMsg: document.querySelector(
      `#${ElementIds.newPriceInputValueErrMsgId}`,
    ),
    collectionIdValue: document
      .querySelector(`#${ElementIds.collectionId}`)
      .innerText.trim(),
    quantityValue: Number(
      document.querySelector(`#${ElementIds.quantityElementId}`).value,
    ),
    quantity: document.querySelector(`#${ElementIds.quantityElementId}`),
    quantityInputValueErrMsg: document.querySelector(
      `#${ElementIds.quantityInputValueErrMsgId}`,
    ),

    alertContainerWrapper: document.querySelector(
      `#${ElementIds.alertContainerWrapperId}`,
    ),
    alertPrimaryTextContainer: document.querySelector(
      `#${ElementIds.alertPrimaryTextContainerId}`,
    ),
    alertSecondaryTextContainer: document.querySelector(
      `#${ElementIds.alertSecondaryTextContainerId}`,
    ),
    priceDisplayContainer: document.querySelector(
      `#${ElementIds.priceDisplayContainerId}`,
    ),
    orgCanEditPriceValue: document
      .querySelector(`#${ElementIds.orgCanEditPriceId}`)
      .innerHTML.trim(),
    defaultSKUTaxRateValue: document
      .querySelector(`#${ElementIds.defaultSKUTaxRateId}`)
      .innerHTML.trim(),
    priceDisplayWrapper: document.querySelector(
      `#${ElementIds.priceDisplayWrapperId}`,
    ),
    skuTaxContainer: document.querySelector(`#${ElementIds.skuTaxContainerId}`),
    taxText: document.querySelector(`#${ElementIds.taxTextId}`),
    totalText: document.querySelector(`#${ElementIds.totalTextId}`),
    subtotalText: document.querySelector(`#${ElementIds.subtotalTextId}`),
    rateCardText: document.querySelector(`#${ElementIds.rateCardTextId}`),
    ratingTableText: document.querySelector(`#${ElementIds.ratingTableTextId}`),
    rateCardAndTableTextParent: document.querySelector(
      `#${ElementIds.rateCardAndTableTextParentId}`,
    ),
    orgVATTaxRateElement: document.querySelector(
      `#${ElementIds.orgVATTaxRateId}`,
    ),
    BUSINESS_PARTNER_PAYMENT_TERM_URL: `/partners/api/partner/payment_term`,
  };

  return constants;
}

export const deliveryFormElementIds = {
  priceSectionId: 'fetch-price-section',
  fetchPriceBtnId: 'fetch-price-btn',
  defaultSKUTaxRateId: 'default-sku-tax-rate',
  orgVATTaxRateId: 'org_vat_tax_rate',
  previousButtonId: 'previous-button',
  salesOrderLineFormId: 'sale-orderline-form',
  editPriceBtnId: 'edit-price-btn',
  errorMessageContainerId: 'error-message-container',
  errorMessageTextId: 'error-message-text',
  newPriceInputId: 'new-parcel-price',
  newPriceInputValueErrMsgId: 'new-parcel-priceError',
  submitParcelItemBtnId: 'submit-parcel-item-btn',
  saveParcelAndShipmentBtnId: 'save-and-generate-shipment',
  salesOrderId: 'sales-order-id-container',
  orgCanEditPriceId: 'org-edit-price',
  pricingSpinnerOverlayId: 'pricing-spinner-overlay',
  classOfServiceElementId: 'id_class_of_service',
  classOfServiceInputValueErrMsgId: 'id_classofserviceError',
  orderDateInputId: 'orderDate',
  orderDateErrorId: 'orderDateError',
  subtotalTextId: 'subtotalText',
  taxTextId: 'taxText',
  totalTextId: 'totalText',
  alertContainerWrapperId: 'deliveryAlertContainer',
  alertPrimaryTextContainerId: 'alertPrimaryText',
  alertSecondaryTextContainerId: 'alertSecondaryText',
  organisationCurrencyId: 'organisation-currency',

  descriptionInputId: 'id_description',
  lengthElementId: 'id_length',
  lengthInputValueErrMsgId: 'id_lengthError',
  widthElementId: 'id_width',
  widthInputValueErrMsgId: 'id_widthError',
  heightElementId: 'id_height',
  heightInputValueErrMsgId: 'id_heightError',
  weightInputId: 'id_weight',
  weightInputValueErrMsgId: 'id_weightError',
  quantityElementId: 'id_quantity',
  quantityInputValueErrMsgId: 'id_quantityError',
  parcel_size_id: 'id_parcel_size',

  weightTextId: 'weightText',
  dimensionTextId: 'dimensionText',
  quantityTextId: 'quantityText',
  originTextId: 'originText',
  destinationTextId: 'destinationText',
  senderNameTextId: 'senderNameText',
  senderPhoneTextId: 'senderPhoneText',
  recipientNameTextId: 'recipientNameText',
  recipientPhoneTextId: 'recipientPhoneText',
  classOfServiceTextId: 'classOfServiceText',
  orderDateTextId: 'orderDateText',
  chargedToTextId: 'chargedToText',

  senderElementId: 'id_sender',
  senderErrorContainerId: 'id_senderError',
  recipientElementId: 'id_recipient',
  recipientErrorElementId: 'id_recipientError',
  originElementId: 'id_origin',
  originErrorContainerId: 'id_originError',
  destinationElementId: 'id_destination',
  destinationErrorElementId: 'id_destinationError',
  orderDateId: 'orderDate',

  paymentTermElementId: 'paymentTermInput',
  paymentTermErrorMessageId: 'payment-term-error-message-container',
  paymentTermErrorMessageContainerId: 'payment-term-error-message-text',
  priceDisplayWrapperId: 'priceDisplayWrapper',
};

export function setupDeliveryFormElementsVariables(ElementIdsObj) {
  const constants = {
    MINIMUM_VALUE: 0,
    ZERO_RATED_VAT: parseFloat(0),
    FETCH_PRICE_URL: `/pricing/sku_pricing/`,

    priceSection: document.querySelector(`#${ElementIdsObj.priceSectionId}`),
    fetchPriceBtn: document.querySelector(`#${ElementIdsObj.fetchPriceBtnId}`),
    defaultSKUTaxRate: document.querySelector(
      `#${ElementIdsObj.defaultSKUTaxRateId}`,
    ),
    previousButton: document.querySelector(
      `#${ElementIdsObj.previousButtonId}`,
    ),
    salesOrderLineForm: document.querySelector(
      `#${ElementIdsObj.salesOrderLineFormId}`,
    ),
    editPriceBtn: document.querySelector(`#${ElementIdsObj.editPriceBtnId}`),
    errorMessageContainer: document.querySelector(
      `#${ElementIdsObj.errorMessageContainerId}`,
    ),
    errorMessageText: document.querySelector(
      `#${ElementIdsObj.errorMessageTextId}`,
    ),
    newPriceInput: document.querySelector(`#${ElementIdsObj.newPriceInputId}`),
    newPriceInputValueErrMsg: document.querySelector(
      `#${ElementIdsObj.newPriceInputValueErrMsgId}`,
    ),
    submitParcelItemBtn: document.querySelector(
      `#${ElementIdsObj.submitParcelItemBtnId}`,
    ),
    saveParcelAndShipmentBtn: document.querySelector(
      `#${ElementIdsObj.saveParcelAndShipmentBtnId}`,
    ),
    salesOrderIdContainer: document.querySelector(
      `#${ElementIdsObj.salesOrderId}`,
    ),
    orgCanEditPrice: document.querySelector(
      `#${ElementIdsObj.orgCanEditPriceId}`,
    ),
    pricingSpinnerOverlay: document.querySelector(
      `#${ElementIdsObj.pricingSpinnerOverlayId}`,
    ),
    orderDateInput: document.querySelector(
      `#${ElementIdsObj.orderDateInputId}`,
    ),
    orderDateError: document.querySelector(
      `#${ElementIdsObj.orderDateErrorId}`,
    ),
    subtotalText: document.querySelector(`#${ElementIdsObj.subtotalTextId}`),
    taxText: document.querySelector(`#${ElementIdsObj.taxTextId}`),
    totalText: document.querySelector(`#${ElementIdsObj.totalTextId}`),
    alertContainerWrapper: document.querySelector(
      `#${ElementIdsObj.alertContainerWrapperId}`,
    ),
    alertPrimaryTextContainer: document.querySelector(
      `#${ElementIdsObj.alertPrimaryTextContainerId}`,
    ),
    alertSecondaryTextContainer: document.querySelector(
      `#${ElementIdsObj.alertSecondaryTextContainerId}`,
    ),
    organisationCurrency: document.querySelector(
      `#${ElementIdsObj.organisationCurrencyId}`,
    ),
    orgVATTaxRateElement: document.querySelector(
      `#${ElementIdsObj.orgVATTaxRateId}`,
    ),

    descriptionInput: document.querySelector(
      `#${ElementIdsObj.descriptionInputId}`,
    ),
    heightElement: document.querySelector(`#${ElementIdsObj.heightElementId}`),
    heightInputValueErrMsg: document.querySelector(
      `#${ElementIdsObj.heightInputValueErrMsgId}`,
    ),
    parcelSizeInput: document.querySelector(`#${ElementIdsObj.parcel_size_id}`),
    widthInput: document.querySelector(`#${ElementIdsObj.widthElementId}`),
    widthInputValueErrMsg: document.querySelector(
      `#${ElementIdsObj.widthInputValueErrMsgId}`,
    ),
    lengthInput: document.querySelector(`#${ElementIdsObj.lengthElementId}`),
    lengthInputValueErrMsg: document.querySelector(
      `#${ElementIdsObj.lengthInputValueErrMsgId}`,
    ),
    weightInput: document.querySelector(`#${ElementIdsObj.weightInputId}`),
    weightInputValueErrMsg: document.querySelector(
      `#${ElementIdsObj.weightInputValueErrMsgId}`,
    ),
    weightText: document.querySelector(`#${ElementIdsObj.weightTextId}`),
    quantity: document.querySelector(`#${ElementIdsObj.quantityElementId}`),
    quantityInputValueErrMsg: document.querySelector(
      `#${ElementIdsObj.quantityInputValueErrMsgId}`,
    ),

    classOfServiceElement: document.querySelector(
      `#${ElementIdsObj.classOfServiceElementId}`,
    ),
    classOfServiceInputValueErrMsg: document.querySelector(
      `#${ElementIdsObj.classOfServiceInputValueErrMsgId}`,
    ),

    dimensionText: document.querySelector(`#${ElementIdsObj.dimensionTextId}`),
    quantityText: document.querySelector(`#${ElementIdsObj.quantityTextId}`),
    originText: document.querySelector(`#${ElementIdsObj.originTextId}`),
    destinationText: document.querySelector(
      `#${ElementIdsObj.destinationTextId}`,
    ),
    senderNameText: document.querySelector(
      `#${ElementIdsObj.senderNameTextId}`,
    ),
    senderPhoneText: document.querySelector(
      `#${ElementIdsObj.senderPhoneTextId}`,
    ),
    recipientNameText: document.querySelector(
      `#${ElementIdsObj.recipientNameTextId}`,
    ),
    recipientPhoneText: document.querySelector(
      `#${ElementIdsObj.recipientPhoneTextId}`,
    ),
    classOfServiceText: document.querySelector(
      `#${ElementIdsObj.classOfServiceTextId}`,
    ),
    orderDateText: document.querySelector(`#${ElementIdsObj.orderDateTextId}`),
    chargedToText: document.querySelector(`#${ElementIdsObj.chargedToTextId}`),

    senderElement: document.querySelector(`#${ElementIdsObj.senderElementId}`),
    senderErrorContainer: document.querySelector(
      `#${ElementIdsObj.senderErrorContainerId}`,
    ),
    recipientElement: document.querySelector(
      `#${ElementIdsObj.recipientElementId}`,
    ),
    recipientErrorElement: document.querySelector(
      `#${ElementIdsObj.recipientErrorElementId}`,
    ),
    originElement: document.querySelector(`#${ElementIdsObj.originElementId}`),
    originErrorContainer: document.querySelector(
      `#${ElementIdsObj.originErrorContainerId}`,
    ),
    destinationElement: document.querySelector(
      `#${ElementIdsObj.destinationElementId}`,
    ),
    destinationErrorElement: document.querySelector(
      `#${ElementIdsObj.destinationErrorElementId}`,
    ),

    priceDisplayWrapper: document.querySelector(
      `#${ElementIdsObj.priceDisplayWrapperId}`,
    ),
    paymentTermElement: document.querySelector(
      `#${ElementIdsObj.paymentTermElementId}`,
    ),
    paymentTermErrorMessage: document.querySelector(
      `#${ElementIdsObj.paymentTermErrorMessageId}`,
    ),
    paymentTermErrorMessageContainer: document.querySelector(
      `#${ElementIdsObj.paymentTermErrorMessageContainerId}`,
    ),

    csrf_token: document.querySelector('input[name=csrfmiddlewaretoken]'),

    BUSINESS_PARTNER_PAYMENT_TERM_URL: `/partners/api/partner/payment_term`,
  };

  return constants;
}
